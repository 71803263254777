import Component from '@/ANTICore/Component';
import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

const defaultOptions = {
  slidesPerView: 3,
  grabCursor: true,
  centeredSlides: true
};

const galleryOptions = {
  slidesPerView: 'auto',
  grabCursor: true,
  centeredSlides: true,
  breakpoints: {
    768: {
      centeredSlides: false
    },
  }
};

const storyOptions = {
  slidesPerView: 1,
  grabCursor: true,
  spaceBetween: 16,
  breakpoints: {
    768: {
      spaceBetween: 24,
      slidesPerView: 3
    },
  }
};

const projectOptions = {
  slidesPerView: 2,
  grabCursor: true,
  spaceBetween: 16,
  breakpoints: {
    768: {
      spaceBetween: 24,
      slidesPerView: 4
    },
  }
};

export default class Slider extends Component {

  options = {}
  hasControls = false;
  slideIndex = 0;
  isBeginning = true;
  isEnd = false;

  onSlideChangeHandler = this.onSlideChange.bind(this);

  constructor(root) {
    super(...arguments);

    this.root = root;
    this.sliderElm = this.root.querySelector('.Slider__inner');
    this.sliderControlsElm = this.root.querySelector('.Slider__controls');
    this.hasControls = this.sliderControlsElm ? true : false;

    this.getConfig(this.root.dataset.sliderConfig);
    this.initSlider(this.sliderElm, this.options);
    setTimeout(() => {this.setSliderPaddingBottom()}, 1000);
  }

  getConfig(config) {
    switch(config) {
      case 'gallery':
        this.options = galleryOptions;
        break;
      case 'stories':
        this.options = storyOptions;
        break;
      case 'projects':
        this.options = projectOptions;
        break;
      default:
        this.options = defaultOptions;
    }
  }

  initSlider(sliderElm, options) {

    if(this.hasControls) {
      const navigation = {
        navigation: {
          nextEl: this.sliderControlsElm.querySelector('.Slider__next'),
          prevEl: this.sliderControlsElm.querySelector('.Slider__prev'),
          disabledClass: 'Slider__nextprev--disabled'
        }
      }
      options = {...options, ...navigation}
    }

    this.swiper = new Swiper(sliderElm, options);
    this.swiper.on('slideChange', this.onSlideChangeHandler);
  }

  onSlideChange() {
    this.isBeginning = this.swiper.isBeginning;
    this.isEnd = this.swiper.isEnd;
    this.slideIndex = this.swiper.activeIndex;
  }

  setSliderPaddingBottom() {
    // Padding bottom for absolute positioned captions
    let tallestCaption = 0;
    [...this.root.querySelectorAll('figcaption')].forEach(el => {
      const elmHeight = el.getBoundingClientRect().height;
      if (elmHeight > tallestCaption) {
        tallestCaption = elmHeight
      }
    });
    if(tallestCaption > 0) {
      // this.sliderElm.style.paddingBottom = tallestCaption + 16 + 'px';
      // this.sliderControlsElm.style.transform = 'translateY(calc(-50%' + ' - ' + tallestCaption / 2 + 'px))';
    }
  }

}
