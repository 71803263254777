// Import favicons
import faviconAlternate from './img/favicon.png';
import favicon from './img/favicon.svg';

// Import fonts used. They go through a file loader that
// put them into dist/fonts when compiled. See how this is
// handeled in line 62 in webpack.common.js
// Import fonts
import '@/fonts/SuisseIntlMono-Regular-WebXL.woff';
import '@/fonts/SuisseIntlMono-Regular-WebXL.woff2';
import '@/fonts/SuisseWorks-Regular-WebXL.woff';
import '@/fonts/SuisseWorks-Regular-WebXL.woff2';
import '@/fonts/SuisseWorks-RegularItalic-WebXL.woff';
import '@/fonts/SuisseWorks-RegularItalic-WebXL.woff2';
import '@/fonts/SuisseIntl-Regular-WebXL.woff';
import '@/fonts/SuisseIntl-Regular-WebXL.woff2';
import '@/fonts/SuisseIntl-RegularItalic-WebXL.woff';
import '@/fonts/SuisseIntl-RegularItalic-WebXL.woff2';


//
// https://babeljs.io/docs/en/babel-plugin-syntax-dynamic-import
//
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

//
// Components
//
import Slider from '@/components/Slider';

//
// Application
//
const GoksoyrMartens = {
  // Options / globals
  body: null,

  init() {
    this.body = document.body;

    // Init sliders
    [...this.body.querySelectorAll('.Slider')].forEach(el => new Slider(el));

  },

  initOnLoad() {
  }
};

document.addEventListener('DOMContentLoaded', () => GoksoyrMartens.init());
window.addEventListener('load', () => GoksoyrMartens.initOnLoad());

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
